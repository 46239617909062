import React, { useEffect, useState } from "react";
import Header from "../../../components/header";
import Layout from "../../../components/layout";
import WithProviders from "../../../components/WithProviders";
import { Message } from "../../../lib/getMessage";

import "./style.css";
import HeroBanner from "../../../components/HeroBanner";

import ServiceProcsSclDesc from "../../images/srvc-procs-scl-desc.png";
import ServiceProcsSclDesc2 from "../../images/srvc-procs-scl-desc@2x.png";
import ServiceProcsSclDesc3 from "../../images/srvc-procs-scl-desc@3x.png";
import ProductWithImage from "../../../components/ProductWithImage";

import ServiceProcsSclBui from "../../images/srvc-procs-scl-bui.png";
import ServiceProcsSclBui2 from "../../images/srvc-procs-scl-bui@2x.png";
import ServiceProcsSclBui3 from "../../images/srvc-procs-scl-bui@3x.png";

import ServiceProcsSclBuiMob from "../../images/srvc-procs-scl-bui-mob.png";
import ServiceProcsSclBuiMob2 from "../../images/srvc-procs-scl-bui-mob@2x.png";
import ServiceProcsSclBuiMob3 from "../../images/srvc-procs-scl-bui-mob@3x.png";

const ProcessScaling = (props) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (window?.innerWidth < 480) {
      setIsMobile(true);
    }
  }, []);
  const { language, updateLanguage, direction, location } = props;
  const isDirectionRTL = direction === "rtl";
  return (
    <Layout
      direction={direction}
      showFooterForm
      language={language}
      location={location}
    >
      <div>
        <Header
          current="service"
          language={language}
          updateLanguage={updateLanguage}
          direction={direction}
        />
        <div className="srvc-procs-scl">
          <HeroBanner
            title={<Message dictKey="services.processscaling.title" />}
            className="srvc-procs-scl-banner"
          />
          <section className="srvc-procs-scl-desc">
            <ProductWithImage
              name="srvc-procs-scl-desc"
              className="srvc-procs-scl-desc"
              description={[
                {
                  type: "p",
                  val: (
                    <Message dictKey="services.processscaling.descripiton1" />
                  ),
                  id: 1,
                },
                {
                  type: "p",
                  val: (
                    <Message dictKey="services.processscaling.descripiton2" />
                  ),
                  id: 2,
                },
              ]}
              image={{
                normal: ServiceProcsSclDesc,
                xx: ServiceProcsSclDesc2,
                xxx: ServiceProcsSclDesc3,
              }}
              isDirectionRTL={isDirectionRTL}
            />
          </section>
          <section className="srvc-procs-scl-bui">
            <div className="header">
              <Message dictKey="services.processscaling.interface.header" />
            </div>
            <div className="media">
              {isMobile ? (
                <img
                  src={ServiceProcsSclBuiMob}
                  srcSet={`${ServiceProcsSclBuiMob2} 2x, ${ServiceProcsSclBuiMob3} 3x`}
                  alt="media"
                  loading="lazy"
                />
              ) : (
                <img
                  src={ServiceProcsSclBui}
                  srcSet={`${ServiceProcsSclBui2} 2x, ${ServiceProcsSclBui3} 3x`}
                  alt="media"
                  loading="lazy"
                />
              )}
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default WithProviders(ProcessScaling);
